<template>
    <div class="cart">
        <div class="cart__wrap">
            <div class="cart__container">
                <h2 class="cart__title">
                    <img class="cart__title--icon" src="~ThemePath/images/cart-green.png" alt="cart" />
                    <span>{{ getTranslatedText('Betslip') }}</span>
                </h2>
                <div class="betslip__header-tab">
                    <template v-for="(v, i) in listMenuGames">
                        <template
                            v-if="
                                (v.step === 1 && $store.state.isShow590) ||
                                (v.step === 2 && $store.state.isShow645) ||
                                (v.step === 3 && $store.state.isShow459) ||
                                (v.step === 4 && $store.state.isShow545) ||
                                (v.step === 5 && $store.state.isShowInstantLotto)
                            "
                        >
                            <div
                                class="betslip__header-tab-item"
                                :class="{ active: $store.state.stepCart === v.step }"
                                @click="() => onChangeTabBetslip(v.step)"
                                :key="i"
                            >
                                {{ getTranslatedText(v.title) }}
                                <div class="betslip__header-quantity">{{ renderQuantityCart(v.step) }}</div>
                            </div>
                        </template>
                    </template>
                </div>
                <button class="cart__btn--close" @click="$emit('close-cart')">
                    <img src="~ThemePath/images/close-green.png" alt="" />
                </button>
                <div class="cart__content">
                    <div class="cart__content__empty" v-if="$store.state.cartRender?.length === 0">
                        <p>{{ getTranslatedText('cart empty') }}</p>
                        <button @click="$emit('continue-paying')" class="cart__content__empty__add-bets">
                            {{ getTranslatedText('Add Bets') }}
                        </button>
                    </div>
                    <div v-else class="cart__content__list--product">
                        <table>
                            <tr v-for="(value, index) in $store.state.cartRender" :key="index">
                                <td>
                                    <span>
                                        {{ getTranslatedText(value.title) }}
                                        <span class="betslip__text bets" v-if="value.isPariMutuelLotto">
                                            ({{
                                                getTranslatedText(value.bets <= 1 ? 'single' : 'multiple[number]', {
                                                    number: value.selection[0].length,
                                                })
                                            }})
                                        </span>
                                    </span>
                                    <div class="cart__content__list--product__numbers">
                                        <span v-for="(v, i) in value.selection[0]" :key="i">
                                            {{ v }}
                                            <span v-if="i < value.selection[0].length - 1">, </span>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span>{{ renderDate(value.eventDate) }}</span>
                                </td>
                                <td>
                                    <span>{{ renderPrice(value.price) }}</span>
                                    <span>
                                        <button @click="onShowConfirmRemoveItem(value)">
                                            <img src="~ThemePath/images/close-green.png" alt="" />
                                        </button>
                                    </span>
                                </td>
                            </tr>
                            <tr class="cart__total">
                                <td>{{ getTranslatedText('total') }}</td>
                                <td>{{ renderPrice(this.$store.state.totalCart) }}</td>
                            </tr>
                        </table>
                        <div class="cart__btn">
                            <div class="cart__btn__carts">
                                <button @click="onShowConfirmClear" class="cart__btn__carts--clear">
                                    {{ getTranslatedText('Clear') }}
                                </button>
                                <button
                                    @click="onSubmitTransact"
                                    class="cart__btn__carts--pay"
                                    :disabled="isLoadingButton"
                                >
                                    <Loading
                                        v-if="isLoadingButton"
                                        :isLoadingPage="false"
                                        :is-loading-white-color="true"
                                    ></Loading>
                                    <span v-if="!isLoadingButton">{{ getTranslatedText('Pay') }}</span>
                                    <span v-if="isLoadingButton">{{ getTranslatedText('Submitting') }}</span>
                                </button>
                            </div>
                            <button
                                :disabled="isLoadingButton"
                                @click="$emit('continue-paying')"
                                class="cart__btn--continue"
                            >
                                <Loading :is-loading-white-color="true" v-if="isLoadingButton" :isLoadingPage="false">
                                </Loading>
                                {{ getTranslatedText('Continue paying') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ReprintDialog />
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import languageMixins from '@/mixins/language/language-mixins'
import pathAssetsMixins from '@/mixins/pathAssets/path-assets-mixins'
import ButtonCommon from '@/components/common/button/index.vue'
import Loading from '@/components/common/loading/index.vue'
import {
    formatDateUTC00,
    formatPrice,
    getDrawName,
    isStrEmpty,
    isArrayEmpty,
    generateCombinations,
} from '@/utils/utils'
import BetslipsService from '@/services/betslip.service'
import { getToken } from '@/services/axios'
import ReprintDialog from '@/components/common/dialog/reprint.vue'
import printMinxin from '@/mixins/print/print-mixin'
import { INSTANT_GAME_TITLE, INSTANT_SLUG_API, INSTANT_SLUG_GAME } from '@/constants/lotto'

const LOCAL_TAX = process.env.VUE_APP_WITHHOLDING_TAX_LOCAL
const TAX_THRESHOLD = process.env.VUE_APP_WITHHOLDING_TAX_FREE_THRESHOLD
const TAX_RATE = process.env.VUE_APP_WITHHOLDING_TAX_RATE

export default {
    mixins: [languageMixins, pathAssetsMixins, printMinxin],
    components: { ButtonCommon, Loading, ReprintDialog },
    props: {
        isLoadingPage: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoadingButton: false,
            isToggle: false,
            listMenuGames: [
                {
                    title: 'loto 5/90',
                    step: 1,
                },
                {
                    title: 'lotto 6 of 45',
                    step: 2,
                },
                {
                    title: 'lotto 4/59',
                    step: 3,
                },
                {
                    title: 'lotto 5 of 45',
                    step: 4,
                },
                {
                    title: INSTANT_GAME_TITLE,
                    step: 5,
                },
            ],
        }
    },
    mounted() {
        this.onChangeTabBetslip(this.$store.state.stepCart || 1)
    },
    created() {
        EventBus.$on('on-remove-item-betslip', (param) => this.onRemoveItem(param))
        EventBus.$on('on-clear-betslip', () => this.onClearBetslip())
    },
    destroyed() {
        EventBus.$off('on-remove-item-betslip')
        EventBus.$off('on-clear-betslip')
    },
    methods: {
        reprintFromDialog(p) {
            parent.postMessage({ command: 'print-rebet-ticket', param: p.paramPrint }, '*')
        },

        onChangeTabBetslip(step) {
            const payload = {
                step,
            }
            this.$store.dispatch('onSwitchBetslip', payload)
        },
        renderQuantityCart(step) {
            let type = 'Loto 4/59'

            if (step === 1) {
                type = 'loto-590'
            } else if (step === 2) {
                type = 'lotto-6-45'
            } else if (step === 3) {
                type = 'loto-4-59'
            } else if (step === 4) {
                type = 'lotto-5-45'
            } else {
                type = INSTANT_SLUG_GAME
            }

            let cartRender = this.$store.state.cart.filter((x) => x.gameType === type)
            return cartRender.length
        },

        onShowConfirmClear() {
            const param = {
                title: process.env.VUE_APP_I18N_LOCALE === 'fr' ? 'warning' : 'confirm delete',
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-clear-betslip',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        showReprintDialog(transaction, paramPrint) {
            this.$store.dispatch('onClearCart')
            let betslipId = transaction?.externalId ?? transaction?.external_id ?? ''
            EventBus.$emit('on-show-reprint', {
                ticketType: 'L',
                reference: transaction?.reference,
                betslipId: betslipId.startsWith('L-') ? betslipId : 'L-' + betslipId,
                paramPrint: paramPrint,
                forLotto: true,
            })
        },

        onShowToast(title, message, code) {
            if (code === 200) {
                this.$store.dispatch('onClearCart')
                this.isLoadingButton = false
            } else {
                this.isLoadingButton = false
            }
            const param = {
                title: title,
                description: message,
                isShowCancel: false,
                nameFunction: 'hideModal',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onShowConfirmRemoveItem(item) {
            const param = {
                title: process.env.VUE_APP_I18N_LOCALE === 'fr' ? 'warning' : 'confirm delete',
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-remove-item-betslip',
                paramFunction: {
                    item,
                },
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onRemoveItem(param) {
            const payload = {
                item: param.item,
            }
            this.$store.dispatch('onRemoveItemCart', payload)
        },

        onClearBetslip() {
            this.$store.dispatch('onClearCart')
        },

        onToggleBetslip() {
            if (this.$store.state.isScreenMobile) {
                this.isToggle = !this.isToggle
                EventBus.$emit('on-toggle-betslip-mobile', this.isToggle)
            }
        },

        onShowStatusTransac(code, message) {
            if (code === 200) {
                const param = {
                    text: message || `Transaction completed!`,
                    type: 'success',
                }
                EventBus.$emit('show-notications', param)
                this.$store.dispatch('onClearCart')

                this.isLoadingButton = false
            } else {
                const param = {
                    text: message || `system error!`,
                    type: 'error',
                }
                EventBus.$emit('show-notications', param)
                this.isLoadingButton = false
            }
        },

        async calculateTaxForTacTac(tactacResp) {
            // setting to calc tax at JS/Client side or call api to get tax
            let useApiToGetTax = LOCAL_TAX != 1;
            if (useApiToGetTax) {
                try {
                    let res = await BetslipsService.calculateWithholdingTax(tactacResp)
                    if (res && res.status == 200 && res.data) return res.data
                } catch (e) {
                    console.log('error when calculate withholding tax from api', e)
                }
            } else {
                try {
                    return await this.calculateTaxForTacTacLocal(tactacResp)
                } catch (e) {
                    console.log('error when calculate withholding tax at local', e)
                }
            }
            return null;
        },

        async calculateTaxForTacTacLocal(tactacResp) {
            let totalWinnings = 0;
            let rate = parseFloat(TAX_RATE)

            tactacResp.bets?.entries?.forEach((e, i) => {
                let calcWins = this.calcTax(e.winnings ?? 0);
                if (calcWins && calcWins.taxAmount && calcWins.taxAmount > 0) {
                    tactacResp.bets.entries[i].tax_rate = (rate * 100) + "%";
                    tactacResp.bets.entries[i].winnings = calcWins.netWinnings;
                    totalWinnings += calcWins.netWinnings;
                } else {
                    tactacResp.bets.entries[i].tax_rate = "0%";
                    totalWinnings += tactacResp.bets.entries[i].winnings
                }
            })

            tactacResp.transaction.total_winnings = totalWinnings;
            return tactacResp;
        },

        calcTax(grossWinningsAmount) {
            grossWinningsAmount = parseFloat(grossWinningsAmount)
            let rate = parseFloat(TAX_RATE)
            let taxThreshold = parseFloat(TAX_THRESHOLD)

            if (parseFloat(0) == rate || grossWinningsAmount <= taxThreshold) {
                return { 'netWinnings': grossWinningsAmount, 'taxAmount': 0 };
            }

            let taxableAmount = grossWinningsAmount - taxThreshold;
            let taxAmount = taxableAmount * rate;
            let netWinningsAmount = grossWinningsAmount - taxAmount;

            return { 'netWinnings': parseFloat(netWinningsAmount), 'taxAmount': taxAmount };
        },

        async onSubmitTransact() {
            try {
                this.isLoadingButton = true
                const bet_timestamp = new Date().getTime() / 1000
                const token = getToken()
                if (!isStrEmpty(token)) {
                    const respCheckdevice = await BetslipsService.deviceBindingcheck()
                    if (respCheckdevice.status === 200) {
                        /*
                            step 1 = 590
                            step 2 = 
                            step 3 = 459
                            step 4 = 545
                        */

                        const {
                            stepCart,
                            transact459,
                            transact545,
                            transact,
                            transactPariLotto,
                            transactInstantLotto,
                        } = this.$store.state

                        let isMultiLotto = stepCart === 1
                        let type = isMultiLotto ? 'multi-lotto' : 'pari-mutuel-lotto'
                        let payload

                        switch (stepCart) {
                            case 1:
                                payload = transact
                                break
                            case 2:
                                payload = transactPariLotto
                                break
                            case 3:
                                payload = transact459
                                break
                            case 4:
                                payload = transact545
                                break
                            case 5:
                                payload = transactInstantLotto
                                type = INSTANT_SLUG_API
                                break
                            default:
                                break
                        }

                        if (!isArrayEmpty(payload)) {
                            payload = payload.map((item) => {
                                const newItem = { ...item }
                                delete newItem.id
                                return newItem
                            })

                            const resp = await BetslipsService.transactLotto(payload, bet_timestamp, type)
                            if (resp.code === 200) {
                                let paramPrint = null
                                let dataEntries = resp.data.bets.entries.map((x) => {
                                    let drawName = ''
                                    if (isMultiLotto) {
                                        this.$store.state.transact = []
                                        const dateFormat = formatDateUTC00(x.event.drawDate)
                                        let date = `${dateFormat.hour}`
                                        drawName = getDrawName(date)
                                    } else {
                                        drawName = this.getTranslatedText(x.game.game)
                                    }

                                    let item = {
                                        ...x,
                                    }
                                    item.game['game'] = this.getTranslatedText(x.game.game)
                                    item.event['drawName'] = drawName

                                    if (stepCart === 2 || stepCart === 4) {
                                        let numberSelect = x.market.market.slice(-1)
                                        if (stepCart === 2)
                                            item.game['game'] += ` ${this.getTranslatedText('Milliardaire')}`
                                        if (stepCart === 4)
                                            item.game['game'] = this.getTranslatedText(item.game['game'])

                                        if (x.entry.bet[0].length > numberSelect) {
                                            item['combinations'] = generateCombinations(
                                                Number(x.entry.bet[0].length),
                                                Number(numberSelect)
                                            )
                                            item['type'] = `${this.getTranslatedText('multiplePrint')}${
                                                x.entry.bet[0].length
                                            }`
                                        } else {
                                            item['type'] = `${this.getTranslatedText('simple')}`
                                            item['combinations'] = 1
                                        }
                                    }

                                    // add show jackpot
                                    var drawNumber = stepCart === 3 ? '459' : stepCart === 4 ? '545' : null
                                    if (drawNumber) {
                                        let firstDraw = JSON.parse(localStorage.getItem('draw-' + drawNumber))
                                        if (firstDraw.event_id == item.event.eventId) {
                                            item.game.jackpot = localStorage.getItem('jackpot-' + drawNumber)
                                        }
                                    }

                                    return item
                                })

                                resp.data.bets['entries'] = dataEntries
                                resp.data['isCombinations'] = !isMultiLotto
                                paramPrint = resp.data

                                if (paramPrint?.transaction?.additionalInformation?.game_slug == INSTANT_SLUG_GAME) {
                                    // https://bitville.atlassian.net/browse/GUI01-574
                                    // tac tac game only
                                    // call wallet's api to get withholding tax if there is winnings
                                    // ignore if crash on api, keep printing current data without tax
                                    let calculatedResp = await this.calculateTaxForTacTac(paramPrint);
                                    if(calculatedResp) paramPrint = calculatedResp
                                }

                                // this.onShowToast('success', resp.message, 200)
                                console.log('paramPrint', paramPrint)
                                this.showReprintDialog(resp?.data?.transaction, paramPrint)

                                // func print
                                this.printLoto(paramPrint)
                                parent.postMessage({ command: 'print-rebet-ticket', param: paramPrint }, '*')
                            } else {
                                this.onShowToast('error', resp.message, 400)
                            }
                        }
                    } else {
                        this.onShowToast('error', respCheckdevice.message, 401)
                    }
                    this.isLoadingButton = false
                } else {
                    const param = {
                        text: `please login!`,
                        type: 'error',
                    }
                    EventBus.$emit('show-notications', param)
                    this.isLoadingButton = false
                }
            } catch (error) {
                this.isLoadingButton = false
                this.onShowToast(
                    'error',
                    error === 'ECONNABORTED'
                        ? this.getTranslatedText('Request timeout. Please check your network connection.')
                        : error,
                    401
                )
                console.log(error)
            }
        },

        // format
        renderDate(date) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`

            return valueDate
        },

        renderPrice(price, decimal = 0) {
            return formatPrice(price, decimal)
        },

        renderDrawDate(date) {
            const dateFormat = formatDateUTC00(date)

            let valueDate = `${dateFormat.hour}`

            return valueDate
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/cart/_index.scss';
</style>
